.settings-form {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;

    .button-link {
        padding-left: 0px;
    }
}

.chnage-password-modal-content {
    .secondary-btn {
        margin-left: 10px;
    }
}
