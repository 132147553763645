@import "../../styles/elements";

.single-grave-top-block {
    width: 100%;
    min-height: 100vh;
    padding-top: 140px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: $theme-violet;

    h1, h2, h3, h4, h5, p {
        color: $theme-violet;
        font-family: $additional-font-family;
    }

    p {
        font-size: 18px;
    }

    .grave-description-text {
        white-space: pre-line;
        font-family: $additional-font-family;
        font-size: 18px;
    }

    .general-container {
        box-shadow: 10px 50px 50px rgba(113, 55, 138, .2);
        background-color: $color-white;
        margin-bottom: 50px;
        max-width: 1200px;
        width: 100%;
        position: relative;
        padding: 40px;

        .general-container-inner {
            padding: 50px;
            border-image: linear-gradient(210deg, #eae4ec, #8a63a0) 50;
            border-width: 4px;
            border-style: solid;
        }

        .map-block {
            position: absolute;
            top: 0;
            right: 0;
            margin-top: 60px;
            margin-right: 60px;
        }

        .flowers-top {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            max-width: 850px;
            width: 100%;
            margin-left: -66px;
            margin-top: -45px;
        }
    }

    .right-container {
        text-align: center;
    }

    .graves-listing-block {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .graves-listing-block-title {
        text-align: center;
        font-weight: 700;
        margin-bottom: 30px;
    }

    .top-image-block {
        text-align: center;
        padding: 0px;
        max-width: 500px;
        width: 100%;
        margin: 30px auto 50px auto;
        padding: 0px;
        border-radius: 0px;
        background-color: transparent;

        .ant-image-img {
            width: 100%;
            min-width: 500px;
            border-radius: 10px;
            // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        }

        .ant-image {
            margin: 0 auto;
        }
    }

    .card-wrapper {
        padding-left: 7px;
        padding-right: 7px;
        padding-bottom: 15px;
    }
    .grave-card {
        border-radius: 16px;
        cursor: pointer;
        min-height: 224px;
        justify-content: center;
        align-items: center;
        height: auto;
        @include transition(all 0.3s ease-in-out);
        color: $theme-violet;
        overflow: hidden;
        border-color: $theme-violet;
        padding-top: 10px;

        .description-content {
            padding-top: 1px;
            margin-top: 10px;
            border-radius: 15px;
            min-height: 172px;
            // border: 1px solid $theme-violet;
            // border-bottom-left-radius: 0px;
            // border-bottom-right-radius: 0px;
            // background-color: $theme-violet;
        }

        .person-name {
            color: $theme-violet;
            text-align: center;
            font-size: 45px;
            font-weight: 400;
            margin-top: 10px;
            margin-bottom: 0px;
            font-family: $secondary-font-family;
            line-height: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }

        .ant-card-body {
            padding: 0;
            width: 100%;
        }
        &:hover {
            transform: scale(1.05);
        }

        .image-content {
            text-align: center;
            width: 100%;
            img {
                width: 95%;
                border-radius: 50%;
                margin: 0 auto;
            }
        }
        img {
            max-height: 400px;
            height: auto;
        }

        .year-block {
            text-align: center;
            padding-bottom: 15px;
            font-size: 25px;
            font-weight: 600;
            font-family: $secondary-font-family;
        }

        .actions-block {
            text-align: center;
            padding-bottom: 25px;
            button {
                border-radius: 30px;
                min-width: 200px;
            }
        }

    }

    .map-button {
        display: flex;
        align-items: center;
    }

    .google-map-icon {
        width: 30px;

    }

    .title-wrap {
        font-family: $secondary-font-family;
        text-align: center;
        margin-top: 150px;

        h1 {
            font-family: $secondary-font-family;
            font-weight: 400;
            font-size: 70px;
            text-align: center;
        }
    }



    @media only screen and (max-width: 1200px) {
        .grave-card {
            .person-name {
                font-size: 40px;
            }
            .year-block {
                text-align: center;
                padding-bottom: 15px;
                font-size: 30px;
                font-weight: 600;
                font-family: $secondary-font-family;
            }
        }
    }


    @media only screen and (max-width: 640px) {
        .top-image-block {
            .ant-image-img {
                width: 100%;
                min-width: 0px;
            }
        }
    }

    @media only screen and (max-width: 510px) {

        .general-container {
            padding: 20px;
            .flowers-top {
                margin-left: -20px;
                margin-top: -45px;
            }

            .general-container-inner {
                padding-left: 10px;
                padding-right: 10px;
                border: 0px;
            }
        }
    }

    @media only screen and (max-width: 480px) {
        padding-top: 120px;
    }
}
.map-modal {
    .ant-modal-title {
        font-family: $primary-font-family;
        font-size: 22px;
        font-weight: 700;
    }
}
.map-modal-button {
    margin-top: 15px;
}
