@import "../../styles/elements";
.timeline-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    .description {
        font-size: 18px;
        color: $theme-violet;
        margin-top: 30px;
        margin-bottom: 30px;
        text-align: center;
    }

    .custom-dot-size {
        color: $theme-violet;
    }


    .timeline-item-title {
        color: $theme-violet;
        font-size: 28px;
    }

    .timeline-item-date {
        color: $theme-violet;
    }

    .timeline-item-description {
        margin-top: 7px;
        margin-bottom: 7px;
        font-size: 18px;
        font-family: $primary-font-family;
        color: $theme-violet;
    }

    .ant-timeline-item-content {
        margin-inline-start: 32px;
    }



    .remove-event-button {
        z-index: 999;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        padding: 0px;
        border-radius: 50%;
        font-size: 12px;
        margin-top: 5px;
        &:hover {
            background-color: $theme-red-color !important;
            color: $color-white;
            border-color: $theme-red-color;
            opacity: 1;
        }
    }

    .ant-timeline-item-left {
        .remove-event-button {
            margin-left: -8px;
        }
    }

    .event-form {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 20px;

        .text-input {
            height: 40px;
        }

        .ant-select {
            height: 40px;
            min-width: 450px;
            width: 100%;
            .ant-select-selector {
                height: 40px;
                input {
                    height: 40px;
                }

                .ant-select-selection-item {
                    align-items: center;
                    display: flex;
                }
            }
        }

        .ant-picker {
            height: 40px;
        }

        button {
            height: 40px;
        }
    }


    .event-form .ant-select {
        max-width: 300px;
        width: 100%;
    }

    .custom-dot-size {
        font-size: 35px;
        margin-top: 10px;
    }

    .ant-timeline-item-head {
        margin-top: 11px !important;
    }

    .ant-timeline .ant-timeline-item {
        padding-bottom: 40px;
    }

    .timeline-item-date {
        svg {
            color: $theme-violet;
            margin-right: 5px;
        }

        text-transform: capitalize;
    }

    .ant-timeline-item-right {
        .timeline-text-wrapper {
            margin-right: 15px;
        }
    }

    @media only screen and (max-width: 580px) {
        max-width: 400px;

        .timeline-item-title {
            font-size: 20px;
        }

        .timeline-item-description {
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 480px) {
        max-width: 300px;
    }

    @media only screen and (max-width: 380px) {
        max-width: 250px;
    }

}
