@import "../../styles/elements";


.not-found {
 text-align: center;

 h1, h2, h3, h4, h5, p {
     color: $theme-violet;
     font-family: $additional-font-family;
 }

 img {
     max-width: 600px;
     width: 100%;
 }
}
