@import "../../styles/elements";

.checkout-page {

    .card-wrapper {
        padding: 10px;
    }

    .payment-plans-row {
        justify-content: center;
    }

    .board-title {
        text-align: center;
    }

    .payment-plan-card {
        min-height: 600px;
        display: flex;
        overflow: hidden;
        cursor: pointer;

        @include transition(all 0.3s ease-in-out);

        .ant-card-body {
            width: 100%;
        }

        .icon-row {
            img {
                width: 100%;
            }

            .number {
                text-align: center;
                margin-top: 0;
                margin-bottom: 0;
                font-size: 30px;
                span {
                    font-weight: 500;
                }
            }
        }

        .card-inner-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .price-block {
            color: $theme-blue-secondary;
            font-size: 45px;
            font-weight: 700;
            line-height: 1;
            margin-top: 0px;
            display: inline-block;
        }

        .price-info {
            display: inline-block;
            margin-left: 10px;
        }

        .plan-titile {
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 0px;
        }

        .plan-description {
            margin-top: 7px;
        }

        .benefit-icon {
            background: $theme-green;
            color: $color-white;
            border-radius: 50%;
            height: 18px;
            width: 18px;
            text-align: center;
            align-items: center;
            justify-content: center;
            display: flex;
            margin-right: 7px;
            font-size: 12px;
        }

        .benefit-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 7px;
        }

        .buy-basic-button {
            width: 100%;
            border-radius: 100px;
            @include box-shadow(0 0 0 6px #f3f3f3);
            white-space: normal;
            height:auto;
            margin-bottom:10px;
        }

        .bottom-subtitle {
            text-align: center;
            margin-bottom: 0px;
        }

        .benefits-block {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
        }

        .payment-bottom-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: auto;
            flex-grow: 1;
            justify-content: flex-end;
        }
    }

    @media only screen and (max-width: 575px) {

        .payment-plan-card {
            .icon-row {
                padding-top: 15px;
            }
        }

    }

}
