@import "../../styles/elements";

.grave-page {
    .grave-page-content {

        padding-top: 30px;
        .board-title {
            text-align: center;
        }

        .board-subtitle {
            text-align: center;
            font-size: 16px;
            max-width: 800px;
            width: 100%;
            margin: 0 auto;
            font-weight: 500;
        }

        .graves-row {
            justify-content: center;
            margin-top: 50px;
        }

        .card-wrapper {
            padding: 10px;
        }

        .scrum-board-add-card-icon {
            margin: 0 auto;
            display: block;
            font-size: 40px;
            background-color: #9e9e9e;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            color: $color-white;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .scrum-board-add-card-text {
            font-weight: bold;
            text-align: center;
        }

        .grave-title {
            font-weight: bold;
            text-align: center;
            font-size: 18px;
        }

        .add-card {
            display: flex;
            border: dashed 2px #757575;
            border-radius: 16px;
            cursor: pointer;
            height: 224px;
            justify-content: center;
            align-items: center;
            @include transition(all 0.3s ease-in-out);

            &:hover {
                transform: scale(1.05);
            }
        }

        .grave-card {
            display: flex;
            border-radius: 16px;
            cursor: pointer;
            height: 224px;
            justify-content: center;
            align-items: center;
            @include transition(all 0.3s ease-in-out);
            &:hover {
                transform: scale(1.05);
            }

            background-color: $theme-blue;
            color: $color-white;
        }

        .grave-card-icon {
            text-align: center;
            font-size: 22px;
            svg:first-child {
                margin-right: 15px;
            }
        }
    }
}
