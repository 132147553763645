@import "../../styles/elements";

.navbar {
    .dekstop-navigation {
        @media only screen and (max-width: 991px) {
            .nav-link {
                font-size: 12px;
            }
        }
    }
}

.navbar {
    width: 94%;
    height: 64px;
    max-width: 1100px;
    background-color: #fff;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
    box-shadow: 0 2px 5px rgba(113, 55, 138, .2);
    z-index: 999;
    @include transition(all 0.3s ease-in-out);

    &.nav-up {
        top: -110px;
    }

    .nav-container {
        height: 64px;
        justify-content: space-around;
        align-items: center;
        display: flex;
    }


    .nav-menu, .logo-wrap-footer {
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .nav-link {
        color: $theme-violet;
        text-align: center;
        padding: 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        @include transition(all 0.3s ease-in-out);
    }

    .nav-link:hover {
        opacity: .5;
        transform: scale(1.02);
    }

    .nav-link.w--current {
        color: #4e1566;
        font-weight: 600;
    }

    .nav-link.sign-in {
        background-color: rgba(113, 55, 138, .2);
        border-radius: 100px;
        margin-left: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .nav-link.footer-nav {
        color: #fff;
    }

    .nav-link.footer-nav.sign-in {
        background-color: rgba(255, 255, 255, .2);
    }

    .logo-link {
        text-decoration: none;
    }

    .logo-link.nav:hover {
        opacity: 1;
    }

    .logo-wrap {
        @include transition(all 0.3s ease-in-out);
        &:hover {
            transform: scale(1.06);
        }
    }

    .logo-text {
        color: $theme-violet;
        text-align: center;
        margin-top: 3px;
        font-size: 19px;
        font-weight: 600;
        line-height: 1.5;
    }

    .logo-text.white {
        color: $color-white;
    }

    .button:hover {
        transform: scale(1.02);
    }

    .w-nav-link {
        position: relative;
        display: inline-block;
        vertical-align: top;
        text-decoration: none;
        padding: 20px;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
    }

    .nav-link.w--current {
        color: $theme-violet;
        font-weight: 600;
    }

    .w-nav-button {
        display: none;
        font-size: 24px;
        overflow: hidden;
    }


    @media only screen and (max-width: 767px) {

        margin-top: 20px;

        .nav-menu {
            display: none;
        }

        .w-nav-button {
            display: block;
        }

        .nav-container {
            justify-content: space-between;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

.ant-drawer-content-wrapper {
    max-width: 378px;
    width: 100% !important;
}

.ant-drawer-wrapper-body {
    .ant-drawer-body {
        padding: 0px;
    }
}

.main-site-drawer {
    .close-button {
        font-size: 24px;
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 15px;
        margin-right: 15px;
    }

    .logo-link {
        .logo-wrap {
            margin-top: 0px;
        }
        .logo-text {
            color: $theme-violet;
            text-align: center;
            margin-top: 3px;
            font-size: 19px;
            font-weight: 600;
            line-height: 1.5;
        }
    }

    .drawer-header {
        padding-top: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $theme-violet;
    }

    .drawer-menu {
        padding: 24px;

        .nav-link {
            width: 100%;
            display: block;
            color: $theme-violet;
            font-size: 22px;
            margin-bottom: 12px;
            font-family: $primary-font-family;
            &.w--current {
                font-weight: bold;
            }
        }
    }
}
