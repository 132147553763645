.main-content {
  padding: 20px;
  margin: 0 auto;
  width: 100%; }

.sider {
  min-height: 100vh;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  max-width: none !important;
  min-width: 0 !important;
  width: 280px !important;
  flex: 0 0 auto !important; }

.sider-top {
  height: 70px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }

.main-site-drawer .menu-wrapper {
  margin-top: 30px; }
  .main-site-drawer .menu-wrapper .ant-menu {
    font-size: 16px; }
  .main-site-drawer .menu-wrapper .ant-btn {
    margin-left: 10px;
    font-size: 16px; }

.main-site-drawer .ant-menu-title-content {
  margin-inline-start: 10px; }

.main-site-drawer .side-menu-logo {
  max-width: 180px; }

.main-site-drawer .ant-menu-light.ant-menu-root.ant-menu-inline {
  border: 0; }

.main-site-drawer .ant-menu-light .ant-menu-item-selected {
  background-color: #e6e3e9 !important; }

.main-site-drawer .ant-menu-light .ant-menu-item-selected {
  color: #4e1566; }
