@import "../../styles/elements";


.guest-book-wrapper {
    .guest-book-table {
        white-space: pre-line;
    }

    .guest-book-open-modal-wrapper {
        text-align: center;
        margin-bottom: 50px;
    }

    .table-actions-col {
        min-width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .guest-book-item {
        border-bottom: 1px solid #e3e3e3;
        padding-bottom: 30px;
        margin-bottom: 30px;
        font-family: $additional-font-family;

        .guest-book-title {
            padding-bottom: 0px;
            margin-bottom: 0px;
            padding-top: 0px;
            margin-top: 0px;
            font-size: 22px;
        }

        .guest-book-date {
            padding-top: 0px;
            margin-top: 0px;
            text-align: right;
            font-weight: 500;
        }

        .guest-book-text {
            white-space: pre-line;
            color: $theme-violet;
            font-size: 16px;
            font-family: $additional-font-family;
        }

        button {
            margin-top: 15px;
        }
    }
}

.guest-book-modal-wrap {
    .not-logged-in-text {
        text-align: center;
        font-size: 18px;
        color: $theme-violet;
        font-family: $additional-font-family;
        font-weight: 500;
    }

    .ant-form-item-control-input-content {
        text-align: center;
    }

    .primary-btn-style {
        max-width: 300px;
        margin: 0 auto;
    }

    .success-message {
        text-align: center;
        font-size: 18px;
        color: $theme-violet;
        font-family: $additional-font-family;
        font-weight: 500;
    }
}
