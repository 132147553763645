@import "../../../styles/elements";
.settings-form {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;

    .button-link {
        padding-left: 0px;
    }

    .link {
        color: $theme-violet;
        font-size: 16px;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

.chnage-password-modal-content {
    .secondary-btn {
        margin-left: 10px;
    }
}
