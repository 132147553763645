$color-white: #ffffff;
$color-green: #006233;
$color-silver-sand: #BABBBC;
$color-sky-blue: #20AAE4;
$color-loght-sky-blue: #EEF6FF;
$themeDarkColor: #4F5959;
$theme-blue: #0A8FDC;
$theme-electric-color: #486173;
$theme-red-color: #ed5e68;
$theme-green: #52c41a;
$theme-blue-secondary: #1677ff;
$theme-violet: #4e1566;
$theme-violet-background: #e6e3e9;

$color-light-grey: #666666;
$color-bg-light-grey: #f5f5f5;

// color
$primary-color: $theme-violet;
$header-bg-color: $color-white;
$body-bg-color: $color-loght-sky-blue;
$primary-button-color: $theme-violet;


// font
$primary-font-family: Manrope, sans-serif;
$secondary-font-family: Carattere, Manrope, sans-serif;
$additional-font-family: Jost, Manrope, sans-serif;
$font-size-title: 30px;
$font-size-title-md: 25px;

@mixin transition($transition) {
  -o-transition: $transition;
  -webkit-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}
