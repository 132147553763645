@import "../../styles/elements";

.top-home-block {
    width: 100%;
    justify-content: center;
    display: flex;
    color: $theme-violet;
    padding-top: 180px;
    padding-bottom: 170px;

    h1, h2, h3, h4, h5, p {
        color: $theme-violet;
        font-family: Manrope, sans-serif;
    }

    p {
        font-size: 18px;
    }

    .left-container {
        justify-content: center;
        display: flex;
        flex-direction: column;

        .btn-link-secondary {
            margin-top: 15px;
            margin-bottom: 30px;
        }
    }

    .right-container {
        text-align: center;
    }

    .side-logo {
        max-width: 700px;
        margin-top: -100px;
    }

    .parallax-roundel-wrap {
      z-index: 10;
      width: 60px;
      height: 60px;
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      border: 1px solid #fff;
      border-radius: 100px;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
      margin-right: -119px;
      padding: 20px;
      display: flex;
      position: absolute;
      top: 0%;
      bottom: auto;
      left: auto;
      right: 0%;
      box-shadow: 0 2px 5px rgba(113, 55, 138, .2);
    }

    .qr-code-lottie {
        width: 100px;
        height: 100px
    }

    @media only screen and (max-width: 991px) {

        padding-top: 150px;
        height: auto;
        padding-bottom: 50px;

        .parallax-roundel-wrap {
            position: relative;
            margin-right: 0px;
            margin-top: 0px;
        }

        .side-logo {
            margin-top: -150px;
        }
    }

    @media only screen and (max-width: 767px) {

        padding-top: 110px;
    }

    @media only screen and (max-width: 480px) {
        padding-top: 100px;

        .left-container {
            .btn-link-secondary {
                margin-top: 15px;
                margin-bottom: 50px;
                margin-left: auto;
                margin-right: auto;
                display: block;
                max-width: 185px;
            }
        }

        .side-logo {
            width: 120%;
            margin-left: -10%;
        }
    }
}

.how-it-works-block {
    position: relative;
    padding-top: 60px;

    h1, h2, h3, h4, h5, p {
        color: $theme-violet;
        font-family: Manrope, sans-serif;
    }

    p {
        margin-top: 0px;
    }

    .steps-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        max-width: 800px;
        margin: 0 auto;
        flex-wrap: wrap;

        .step-item {
            flex-direction: row;
            display: flex;
            margin-bottom: 15px;
        }

        .step-description {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 15px;
        }

        .step-image {
            img {
                width: 200px;
                height: 200px;
                border-radius: 50%;
                border: 3px solid $theme-violet;
                background-color: white;
                padding: 10px;
            }
        }
    }

    .how-icon-wrap {
      width: 50px;
      height: 50px;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      border: 1px solid #fcfcfc;
      border-radius: 100px;
      justify-content: center;
      align-items: center;
      margin-top: -10px;
      margin-right: -10px;
      padding: 10px;
      display: flex;
      position: absolute;
      top: 0%;
      bottom: auto;
      left: auto;
      right: 0%;
      box-shadow: 0 2px 5px rgba(113, 55, 138, .2);
    }

    .how-icon {
      width: 40px;
      height: 40px;
    }

    .copy-holder{
        text-align: center;
        max-width: 420px;
        width: 100%;
        margin: 0 auto;
    }

    .custom-col {
        max-width: 356px;
        width: 100%;
    }

    @media only screen and (max-width: 1155px) {
        .ant-row-space-between {
            justify-content: space-evenly;
        }
    }

    @media only screen and (max-width: 520px) {

        .steps-wrapper {
            .step-item {
                flex-direction: column;
                display: flex;
                margin-bottom: 15px;
                align-items: center;
                text-align: center;
            }

            .step-title-text {
                text-align: center;
                width: 100%;
            }
        }
    }
}

.why-important-block {
    position: relative;
    padding-top: 60px;
    padding-bottom: 70px;
    margin-top: 70px;
    h1, h2, h3, h4, h5, p {
        color: $theme-violet;
        font-family: $primary-font-family;
    }

    p {
      font-size: 18px;
    }

    .left-container {
        img{
            width: 100%;
            max-width: 500px;
        }
        text-align: center;
    }

    .right-container {
        text-align: center;
        display: flex;
        align-items: center;
    }

    @media only screen and (max-width: 767px) {

        padding-top: 0px;
        margin-top: 40px;
    }
}


.video-block {
    margin-top: 80px;
    text-align: center;

    .video-button {
        font-size: 50px;
        cursor: pointer;
        @include transition(all 0.3s ease-in-out);
        font-family: $primary-font-family;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-white;
        padding: 20px;
        max-width: 500px;
        margin: 0 auto;
        border-radius: 30px;
        box-shadow: 0 2px 5px rgba(113, 55, 138, .2);

        .video-text {
            font-size: 27px;
            margin-top: 0px;
            margin-left: 15px;
            font-weight: 600;
            display: inline-block;
        }

        &:hover {
            transform: scale(1.1);
        }
    }

    .btn-secondary {
        font-size: 22px;
        font-family: Manrope, sans-serif;
        color: $theme-violet;
        height: auto !important;
        &:hover {
            background-color: $theme-violet;
            color: $color-white !important;
        }

    }

    @media only screen and (max-width: 600px) {
        margin-top: 50px;
        .video-button {
            .video-text {
                font-size: 22px;
            }
        }
    }
}

.video-modal {
    iframe {
        width: 100%;
    }
}
