.header {
  z-index: 1;
  height: 70px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  padding: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  align-items: center; }
  .header.floating-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0; }
  .header .header-content-wrapper {
    display: flex;
    background-color: 'red';
    width: 100%; }
  .header button {
    margin-left: auto; }
  .header .profile-title {
    margin-left: auto;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex; }
    .header .profile-title span {
      margin-left: 7px; }
  .header .w-nav-button {
    display: none;
    font-size: 24px;
    overflow: hidden;
    align-items: center; }
  .header .logo-wrap {
    text-align: center;
    margin-top: 0px;
    display: none;
    flex-direction: row; }
    .header .logo-wrap .logo-text {
      color: #4e1566;
      text-align: center;
      font-size: 19px;
      font-weight: 600;
      line-height: 1.5;
      margin-left: 10px; }

.dropdown-overlay .ant-dropdown-menu-item-active {
  background-color: white !important; }

@media only screen and (max-width: 991px) {
  .header .w-nav-button {
    display: flex; }
  .header .ant-dropdown-trigger {
    display: none; }
  .header .header-content-wrapper {
    justify-content: space-between; }
  .header .logo-wrap {
    display: flex; }
  .header .header-disclaimer {
    display: none; }
  .ant-layout-sider {
    display: none; } }
