.logo-wrap-custom {
  text-align: center;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #4e1566;
  box-sizing: border-box; }
  .logo-wrap-custom .logo-text {
    color: #4e1566;
    text-align: center;
    font-size: 19px;
    font-weight: 600;
    line-height: 1.5;
    margin-left: 10px; }
