@import "../../styles/elements";

.grave-page {
    .grave-page-content {

        .general-grey-content-wrapper {
            text-align: center;
            color: $theme-violet;
        }

        padding-top: 30px;
        .board-title {
            text-align: center;
        }

        .board-subtitle {
            font-size: 16px;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .graves-listing-row {
            justify-content: center;
        }

        .card-wrapper {
            padding: 10px;
        }

        .scrum-board-add-card-icon {
            margin: 0 auto;
            display: block;
            font-size: 40px;
            background-color: #9e9e9e;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            color: $color-white;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .scrum-board-add-card-text {
            font-weight: bold;
            text-align: center;
        }

        .add-card {
            display: flex;
            border: dashed 2px #757575;
            border-radius: 16px;
            cursor: pointer;
            height: 224px;
            justify-content: center;
            align-items: center;
            @include transition(all 0.3s ease-in-out);

            &:hover {
                transform: scale(1.05);
            }
        }

        .grave-card {

            border-radius: 16px;
            cursor: pointer;
            min-height: 224px;
            justify-content: center;
            align-items: center;
            height: auto;
            @include transition(all 0.3s ease-in-out);
            background-color: $theme-electric-color;
            color: $color-white;
            overflow: hidden;

            .ant-card-body {
                padding: 0;
                width: 100%;
            }
            &:hover {
                transform: scale(1.05);
            }

            .image-content {
                text-align: center;
                width: 100%;
                height: 400px;
                background-size: cover;
                background-position: center;
            }

            img {
                max-height: 400px;
                height: auto;
            }

            .person-name {
                text-align: center;
                font-size: 18px;
                font-weight: 600;
            }

            .year-block {
                text-align: center;
                padding-bottom: 15px;
            }

            .description-content {
                position: relative;
            }

            .order-block {
                font-size: 20px;
                position: absolute;
                bottom: 0;
                right: 0;
                margin-right: 5px;
                margin-bottom: 5px;
                cursor:grab;
            }

        }

        .grave-card-icon {
            text-align: center;
            font-size: 22px;
            svg:first-child {
                margin-right: 15px;
            }
        }

        .button-wrapper {
            margin-bottom: 15px;
            justify-content: flex-end;
            align-items: flex-end;
            display: flex;
            flex-direction: row;
            margin-top: -50px;
            margin-bottom: 15px;
        }

        .code-wrapper {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 15px;
            text-align: center;
        }

        .ant-tabs-nav-list {
            .ant-tabs-tab-btn {
                font-size: 18px;
                font-family: $additional-font-family;
            }
            .ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: $theme-violet;
                    font-weight: 600;
                }
            }
        }
    }
}

.secion-title {
    text-align: center;
}

.date-left-col {
    padding-right: 7px;
}

.date-right-col {
    padding-left: 7px;
}

.modal-action-col {
    margin-top: 15px;
}

@media only screen and (max-width: 991px) {
    .grave-action-col {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 560px) {

    .ant-tabs-nav-list {
        display: block !important;
    }

    .ant-tabs-nav-wrap {
        white-space: inherit !important;
    }
    .ant-tabs-tab {
        width: 95%;
        overflow: hidden;
    }

    .ant-card-body {
        overflow: hidden;
    }

}
