@import "../../styles/elements";

.top-faq-block {
    width: 100%;
    padding-top: 163px;
    padding-bottom: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: $theme-violet;

    h1, h2, h3, h4, h5, p, ul, li {
        color: $theme-violet;
        font-family: Manrope, sans-serif;
    }

    p, ul, li {
        font-size: 18px;
    }

    .ant-collapse {
        margin-top: 30px;
        background-color: $theme-violet;
        max-width: 970px;
    }

    .ant-collapse-header {
        color: $color-white !important;
        font-size: 20px;
    }
}
