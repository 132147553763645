@import "../../styles/elements";

.guest-book-table {
    white-space: pre-line;
}
.table-actions-col {
    min-width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.guest-book-item {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 15px;

    .guest-book-title {
        padding-bottom: 0px;
        margin-bottom: 0px;
        padding-top: 0px;
        margin-top: 0px;
        font-size: 18px;
    }

    .guest-book-date {
        padding-top: 0px;
        margin-top: 0px;
        text-align: right;
    }

    .guest-book-text {
        white-space: pre-line;
    }

    button {
        margin-top: 15px;
    }
}
