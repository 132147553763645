@import "../../styles/elements";

.qr-code-search-block {
    width: 100%;
    min-height: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: $theme-violet;
    padding-top: 180px;
    padding-bottom: 50px;

    h1, h2, h3, h4, h5, p {
        color: $theme-violet;
        font-family: Manrope, sans-serif;
    }

    p {
        font-size: 18px;
    }

    .ant-spin-dot-item {
      background-color: $theme-violet;
    }





    @media only screen and (max-width: 991px) {

    }

    @media only screen and (max-width: 480px) {

    }
}
