@import "../../styles/elements";

.top-about-us-block {
    width: 100%;
    min-height: 100vh;
    padding-top: 163px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: $theme-violet;

    h1, h2, h3, h4, h5, p {
        color: $theme-violet;
        font-family: Manrope, sans-serif;
    }

    p {
        font-size: 18px;
    }

    .left-container {
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .right-container {
        text-align: center;
    }

    .side-logo {
        max-width: 700px;
        margin-top: -100px;
    }

    .parallax-roundel-wrap {
      z-index: 10;
      width: 60px;
      height: 60px;
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      border: 1px solid #fff;
      border-radius: 100px;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
      margin-right: -119px;
      padding: 20px;
      display: flex;
      position: absolute;
      top: 0%;
      bottom: auto;
      left: auto;
      right: 0%;
      box-shadow: 0 2px 5px rgba(113, 55, 138, .2);
    }

    .qr-code-lottie {
        width: 100px;
        height: 100px
    }

    @media only screen and (max-width: 991px) {

        padding-top: 150px;
        height: auto;

        .parallax-roundel-wrap {
            position: relative;
            margin-right: 0px;
            margin-top: 0px;
        }

        .side-logo {
            margin-top: -150px;
        }
    }

    @media only screen and (max-width: 480px) {
        padding-top: 120px;
    }
}
