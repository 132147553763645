@import "../../styles/elements";

.footer {
    width: 94%;
    max-width: 1100px;
    background-color: $theme-violet;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 26px 36px;
    display: flex;
    margin-bottom: -10px;
    margin-top: 70px;

    .logo-wrap-footer {
        justify-content: center;
        align-items: center;
        display: flex;
        @include transition(all 0.3s ease-in-out);
        &:hover {
            transform: scale(1.05);
        }
    }

    .logo-text {
        text-align: center;
        margin-top: 3px;
        font-size: 19px;
        font-weight: 600;
        line-height: 1.5;
    }

    .lotus-img {
        margin-right: 10px;
    }

    .footer-nav.sign-in {
        background-color: rgba(255, 255, 255, .2);
    }

    .nav-link {
        color: $color-white;
        text-align: center;
        padding: 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        @include transition(all 0.3s ease-in-out);

        &:hover {
            opacity: 0.5;
        }

        &.sign-in {
            border-radius: 100px;
            margin-left: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    @media only screen and (max-width: 850px) {
        flex-direction: column;
        width: auto;

        .footer-nav-menu {
            margin-top: 15px;
        }
    }

    @media only screen and (max-width: 630px) {
        .nav-link {
            display: block;
        }
    }
}
