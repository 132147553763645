.login-form-box {
    .secondary-button {
        margin-top: 15px;
        width: 100%;
    }
}

.forgot-password-modal {
    .title-text-md {
        margin-top: 0;
    }
}

.signup-modal {
    .title-text-md {
        margin-top: 0;
    }
}

.login-google-container {
    text-align: center;
}
