@import "../../styles/elements";

.single-grave-people-top-block {
    width: 100%;
    padding-top: 140px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: $theme-violet;
    font-family: $additional-font-family;
    h2, h3, h4, h5, h6, p {
        font-family: $additional-font-family !important;
    }

    .general-container {
        box-shadow: 10px 50px 50px rgba(113, 55, 138, .2);
        background-color: white;
        // background-image: url('../../assets/images/upscale.png');
        background-size: 100% auto;
        background-repeat: no-repeat;
        max-width: 1200px;
        width: 100%;
        margin-bottom: 50px;
        padding: 40px;
        position: relative;
        box-sizing: border-box;

        .general-container-inner {
            padding: 50px;
            border-image: linear-gradient(210deg, #eae4ec, #8a63a0) 50;
            border-width: 4px;
            border-style: solid;
        }

        .flowers-top {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            max-width: 600px;
            width: 100%;
        }

        .flowers-bottom {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 0;
            max-width: 600px;
            width: 100%;
        }
    }

    h1, h2, h3, h4, h5, p {
        color: $theme-violet;
        font-family: Manrope, sans-serif;
    }

    p {
        font-size: 18px;
    }

    .right-container {
        padding-left: 30px;
    }

    .top-block-content {
        text-align: center;
    }

    .title-wrap {
        font-family: $secondary-font-family;
        text-align: center;

        h1 {
            font-family: $secondary-font-family;
            font-weight: 400;
            font-size: 70px;
            text-align: center;
        }
    }

    .breadcrumb-block {
        text-align: left;
        max-width: 1280px;
        width: 100%;
        a, button {
            color: $theme-violet;
            font-family: $additional-font-family;
            font-weight: 700;
            display: flex;
            align-items: center;
            @include transition(all 0.2s ease-in-out);
            padding-left: 0px;
            font-size: 18px;
            span {
                margin-left: 7px;
            }

            &:hover {
                opacity: 0.7;
                color: $theme-violet;
            }
        }
        padding-bottom: 15px;
    }

    .additional-videos-block {
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;

        .video-listing-item {
            margin-right: 7px;
        }
    }

    .additional-images-block {
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .single-image-wrap {
            background: $color-white;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            position: relative;
            max-width: 230px;
            width: 100%;
            padding: 5px;


            .image-overlay {
                position: absolute;
                background-color: rgba(0, 0, 0, 0.5);
                width: 100%;
                height: 100%;
                color: $color-white;
                font-size: 18px;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            .ant-image-img {
                height: auto;
                width: 100%;
                border-radius: 5px;
            }

        }
    }

    .person-date {
        margin-top: 0px;
        font-weight: bold;
        font-family: $additional-font-family;
        .month {
            text-transform: capitalize;
        }
    }

    .additional-content-block {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .ant-tabs-nav {
        margin-bottom: 0px;
        padding-left: 5px;

        &::before {
            border-bottom: none;
        }
    }

    .ant-tabs-nav-list {
        .ant-tabs-tab-btn {
            font-size: 18px;
            font-family: $additional-font-family;
        }
        .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                color: $theme-violet;
                font-weight: 600;
            }
        }
    }

    .main-image-wrapper {


        .image-block {
            background-color: $color-white;
            max-width: 400px;
            max-height: 400px;
            width: 100%;
            border-radius: 50%;
            margin: 30px auto;
            overflow: hidden;
        }
        .ant-image {
            margin: 0 auto;
            display: block;
        }
    }

    .guest-book-wrapper {
        margin-top: 30px;
    }

    :where(.css-dev-only-do-not-override-ed5zg0).ant-tabs .ant-tabs-tab:hover {
        color: $theme-violet;
        background-color: $color-white;
    }

    :where(.css-dev-only-do-not-override-ed5zg0).ant-tabs {
        color: $theme-violet;
    }

    @media only screen and (max-width: 1213px) {
        .additional-images-block {
            .single-image-wrap {
                max-width: 31%;
            }
        }
    }

    @media only screen and (max-width: 991px) {

        .right-container {
            padding-left: 0px;
        }

    }

    @media only screen and (max-width: 740px) {
        .additional-videos-block {
            margin: 15px auto;
            width: 300px;
        }
    }

    @media only screen and (max-width: 700px) {
        .additional-images-block {
            .single-image-wrap {
                max-width: 47%;
            }
        }
    }

    @media only screen and (max-width: 586px) {
        .additional-images-block {
            .single-image-wrap {
                max-width: 100%;

                .ant-image {
                    width: 100%;
                }
            }
        }

        .image-gallery-card {
            .ant-card-body {
                padding: 0;

                .block-title {
                    padding-left: 24px;
                    padding-top: 14px;
                }
            }
        }
    }

    @media only screen and (max-width: 560px) {

        .general-container {
            padding-left: 15px;
            padding-right: 15px;
        }
        .ant-tabs-nav-list {
            display: block !important;
        }

        .ant-tabs-nav-wrap {
            white-space: inherit !important;
        }
        .ant-tabs-tab {
            width: 100%;
            overflow: hidden;
        }

        .ant-card-body {
            overflow: hidden;
        }

    }

    @media only screen and (max-width: 510px) {

        .general-container {
            padding: 20px;
            .general-container-inner {
                padding-left: 10px;
                padding-right: 10px;
                border: 0px;
            }
        }

        .title-wrap {
            h1 {
                font-size: 50px;
            }
        }
    }

    @media only screen and (max-width: 480px) {
        padding-top: 120px;
    }
}
.map-modal {
    .ant-modal-title {
        font-family: $additional-font-family;
        font-size: 22px;
        font-weight: 700;
    }
}
.map-modal-button {
    margin-top: 15px;
}
