@import "../../styles/elements";

.videos-page {

    .title-wrapper {
        text-shadow: 0px 0px 4px #ffffff;
    }

    .videos-background {
        position: absolute;
        top: 0;
        z-index: 0;
        width: 100%;
        max-width: 90%;
        margin: 150px auto 0 auto;
        @include box-shadow(0 2px 55px rgba(113, 55, 138, .2));
    }

    .videos-block {
        width: 100%;
        padding-top: 360px;
        padding-bottom: 170px;
        min-height: 670px;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        color: $theme-violet;

        .block-inner {
            background-color: rgba(255, 255, 255, 0.9);
            width: 100%;
            max-width: 900px;
            margin: 0 auto;
            padding: 30px;
            @include box-shadow(0 2px 5px rgba(113, 55, 138, .2));
            position: relative;
            overflow: hidden;
            padding-top: 20px;
        }

        h1 {
            text-align: left;
            font-weight: 800;
            border-bottom: 1px solid $theme-violet;
            margin-bottom: 20px;
            padding-bottom: 10px;
        }

        p, h3 {
            text-align: center;
        }

        .contact-details-wrapper {
            margin-top: 50px;
        }

        h1, h2, h3, h4, h5, p {
            color: $theme-violet;
            font-family: $primary-font-family;
        }

        .divider {
            border-bottom: 1px solid $theme-violet;
            max-width: 250px;
            margin: 20px auto;
        }

        p {
            font-size: 18px;
        }

        a {
            color: $theme-violet;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }

        .ant-collapse {
            margin-top: 30px;
            background-color: $theme-violet;
            max-width: 970px;
        }

        .ant-collapse-header {
            color: $color-white !important;
            font-size: 20px;
        }

        @media only screen and (max-width: 1630px) {
            padding-top: 300px;
            padding-bottom: 0px;
        }

        @media only screen and (max-width: 1260px) {
            padding-top: 220px;
        }

        @media only screen and (max-width: 1064px) {
            padding-top: 170px;
        }

        @media only screen and (max-width: 767px) {
            padding-top: 120px;
            padding-bottom: 50px;
        }


        @media only screen and (max-width: 470px) {
            padding-bottom: 0px;
        }
    }

    @media only screen and (max-width: 1200px) {
        .videos-background {
            max-width: 99%;
        }
    }

    @media only screen and (max-width: 767px) {
        .videos-background {
            display: none;
        }
    }

    .description-wrapper {
        max-width: 700px;
        margin: 40px auto 20px auto;
        text-shadow: 0px 0px 4px #ffffff;
        border-radius: 30px;
        padding: 20px;
        .custom-link {
            margin: 0 auto;
            display: block;
            font-size: 20px;
            text-align: center;
            border: 2px solid $theme-violet;
            border-radius: 15px;
            max-width: 300px;
            text-decoration: none;
            font-weight: 500;
            @include box-shadow(0 0 5px 0px rgba(0,0,0,0.1));
            @include transition(all 0.3s ease-in-out);
            &:hover {
                background-color: $theme-violet;
                color: $color-white;

            }


        }
    }

    .videos-wrapper {
        max-width: 1100px;
        width: 100%;
        margin: 0 auto;

        .single-video-wrapper {
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 15px;
            cursor: pointer;
            @include transition(all 0.2s ease-in-out);
            &:hover{
                transform: scale(1.05);
            }

            .video-title {
                text-align: left;
                font-weight: 800;
                padding-left: 10px;
                margin-top: 5px;
            }

            .placeholder-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 50px;
                color: rgba(255,0,0, 1);
            }

            span {
                position: absolute;
            }

            .video-placeholder {
                width: 100%;
                border-radius: 15px;
            }
            iframe {
                border-radius: 15px;
            }
        }
    }

    .card-wrapper {
        padding: 10px;
    }

    .videos-row {
        justify-content: center;
        margin-bottom: 40px;
    }

    .board-title {
        text-align: center;
    }

    .video-card {
        min-height: 400px;
        display: flex;
        overflow: hidden;
        cursor: pointer;

        @include transition(all 0.3s ease-in-out);
        &:hover {
            transform: scale(1.05);
        }

        .ant-card-body {
            width: 100%;
        }

        .icon-row {
            img {
                width: 100%;
            }

            .number {
                text-align: center;
                margin-top: 0;
                margin-bottom: 0;
                font-size: 30px;
                span {
                    font-weight: 500;
                }
            }
        }

        .card-inner-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .price-block {
            color: $theme-blue-secondary;
            font-size: 45px;
            font-weight: 700;
            line-height: 1;
            margin-top: 0px;
            display: inline-block;
        }

        .old-price-block {
            color: red;
            font-size: 18px;
            font-weight: 600;
            text-decoration: line-through;
        }

        .price-info {
            display: inline-block;
            margin-left: 10px;
        }

        .plan-titile {
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 0px;
        }

        .plan-description {
            margin-top: 7px;
        }

        .benefit-icon {
            background: $theme-green;
            color: $color-white;
            border-radius: 50%;
            height: 18px;
            width: 18px;
            text-align: center;
            align-items: center;
            justify-content: center;
            display: flex;
            margin-right: 7px;
            font-size: 12px;
        }

        .benefit-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 7px;
        }

        .buy-basic-button {
            width: 100%;
            border-radius: 100px;
            @include box-shadow(0 0 0 6px #f3f3f3);
            white-space: normal;
            height:auto;
            margin-bottom:10px;
        }

        .bottom-subtitle {
            text-align: center;
            margin-bottom: 0px;
        }

        .benefits-block {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
        }

        .payment-bottom-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: auto;
            flex-grow: 1;
            justify-content: flex-end;
        }
    }

    @media only screen and (max-width: 766px) {

        .payment-plan-card {
            .icon-row {
                padding-top: 15px;
            }
        }

    }

}
