@import "../../styles/elements";

.grave-page {
    .add-youtube-button {
        background-color: rgba(0, 0, 0, 0.02);
        border: 1px dashed #d9d9d9;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        flex-direction: column;
        width: 102px;
        height: 102px;
        margin-right: 8px;
        cursor: pointer;
        span {
            margin-top: 10px;
        }
    }
    .grave-page-content {

        padding-top: 30px;
        .board-title {
            text-align: center;
        }

        .person-title {
            text-align: center;
        }

        .editable-upper-wrapper {
            margin-top: 30px;
        }
        .year-title {
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
        }

        .date-divider {
            width: 20px;
            height: 2px;
            background: grey;
            margin-left: 15px;
            margin-right: 15px;
        }

        .main-picture {
            margin: 0 auto;
            max-width: 100%;
            max-height: 600px;
        }

        .picture-wrapper {
            text-align: center;
        }

        .col-wrapper {
            padding-left: 7px;
            padding-right: 7px;
            margin-bottom: 15px;
        }

        .save-button {
            margin-top: 30px;
        }

        .image-gallery-listing {

            .ant-upload-wrapper {
                margin-bottom: 15px;
                margin-top: 10px;
            }

            .gallery-listing-item {
                width: 150px;
                height: 150px;
                border-radius: 10px;
                overflow: hidden;
                margin-right: 5px;
                margin-bottom: 8px;
                position: relative;
                display: inline-block;
                img {
                    width: 100%;
                }

                .remove-image-button {
                    position: absolute;
                    z-index: 999;
                    top: 0;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    padding: 0px;
                    border-radius: 50%;
                    font-size: 8px;
                    &:hover {
                        background-color: $theme-red-color !important;
                        color: $color-white;
                        border-color: $theme-red-color;
                        opacity: 1;
                    }
                }
            }

            .video-listing-item {
                width: 300px;
                height: 168px;
                border-radius: 10px;
                overflow: hidden;
                margin-right: 5px;
                margin-bottom: 8px;
                position: relative;
                img {
                    width: 100%;
                }

                .remove-image-button {
                    position: absolute;
                    z-index: 999;
                    top: 0;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    padding: 0px;
                    border-radius: 50%;
                    font-size: 8px;
                    &:hover {
                        background-color: $theme-red-color !important;
                        color: $color-white;
                        border-color: $theme-red-color;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .ant-tabs-nav {
        margin-bottom: 0px;
        padding-left: 15px;
    }

    .block-title {
        text-align: center;
    }

    .grave-action-col {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
    }

    .view-button {
        margin-left: 5px;
        display: inline-block;
        span {
            margin-top: 0px;
        }
    }

    @media only screen and (max-width: 880px) {
        .grave-action-col {
            margin-top: 15px;
        }
    }

    @media only screen and (max-width: 520px) {
        .ant-card-body {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media only screen and (max-width: 502px) {
        .view-button {
            margin-top: 10px;
        }

        .main-content {
            padding-left: 0px;
            padding-right: 0px;
        }

        .ant-breadcrumb {
            padding-left: 10px;
            padding-right: 10px;
        }

        .grave-action-col {
            padding-right: 15px;
        }
    }

}
