@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Carattere&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");
body {
  font-family: Manrope, sans-serif;
  color: #4F5959; }

h1 {
  font-size: 24px;
  color: #4e1566; }

body {
  color: #4e1566;
  background-color: #e6e3e9;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  line-height: 1.5; }

h1 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 45px;
  font-weight: 700;
  line-height: 1.1; }

h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 32px;
  font-weight: 700;
  line-height: 36px; }

h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px; }

@media only screen and (max-width: 480px) {
  h1 {
    font-size: 30px; }
  h2 {
    font-size: 24px; }
  h3 {
    font-size: 20px; } }

a {
  text-decoration: none; }

.general-container {
  max-width: 1100px;
  display: block;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px; }

.img-responsive {
  width: 100%; }

.p-1 {
  padding: 4px; }

.p-2 {
  padding: 8px; }

.p-4 {
  padding: 16px; }

.p-8 {
  padding: 32px; }

.p-12 {
  padding: 48px; }

.p-16 {
  padding: 64px; }

.mb-1 {
  margin-bottom: 4px; }

.mb-2 {
  margin-bottom: 8px; }

.mb-4 {
  margin-bottom: 16px; }

.mb-8 {
  margin-bottom: 32px; }

.mb-16 {
  margin-bottom: 64px; }

.mt-1 {
  margin-top: 4px; }

.mt-2 {
  margin-top: 8px; }

.mt-4 {
  margin-top: 16px; }

.mt-8 {
  margin-top: 32px; }

.mt-16 {
  margin-top: 64px; }

.mlp-25 {
  margin-left: 25%; }

.title-text {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px; }

.title-text-md {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px; }

.ant-btn {
  font-size: 16px;
  height: 37px; }

.ant-btn-primary {
  background: #4e1566; }

.ant-btn-secondary {
  border-color: #4e1566;
  color: #4e1566;
  border: 2px solid #4e1566;
  font-weight: 500; }
  .ant-btn-secondary:hover {
    background-color: #46156629; }

.btn.btn-primary {
  background-color: #4e1566;
  border-radius: 5px;
  padding: 8px 20px;
  color: #ffffff;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500; }
  .btn.btn-primary:hover {
    opacity: 0.7; }

.btn span {
  margin-top: 15px;
  margin-left: 5px; }

.ant-btn-lg {
  height: 55px; }

.ant-btn-primary:hover, .ant-btn-primary:focus {
  border-color: #4e1566;
  background: #4e1566;
  background-color: #4e1566 !important;
  opacity: 0.8; }

.ant-input-affix-wrapper > input.ant-input {
  height: 40px;
  padding: 20px 15px;
  border-radius: 7px; }

:where(.css-dev-only-do-not-override-ed5zg0).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px;
  border-radius: 7px;
  align-items: center; }

.ant-btn-link {
  color: #4e1566; }

.ant-input-affix-wrapper {
  height: 50px;
  border-radius: 7px; }

.ant-input {
  height: 50px;
  padding: 20px 25px;
  border-radius: 7px; }

.ant-avatar {
  background-color: #ccc0; }

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); }

.modal-form-wrap {
  padding-top: 15px;
  padding-bottom: 15px; }

.editable-wrapper {
  cursor: pointer; }

.editable-upper-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.editable-text-save-button {
  margin-left: 8px;
  padding: 0;
  width: 40px !important;
  height: 40px !important; }

.ant-breadcrumb-link {
  font-size: 16px; }

.full-date-picker {
  width: 100%;
  height: 50px; }

.general-grey-content-wrapper {
  background: #f5f5f5;
  padding-top: 1px;
  padding-bottom: 15px; }

.question-popover {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  background: #f5f5f5;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  padding-top: 0;
  margin-top: 0;
  line-height: 1.3;
  font-weight: 500;
  cursor: pointer; }

.lotus-img {
  width: 60px; }

:where(.css-dev-only-do-not-override-ed5zg0).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-ed5zg0).ant-menu-light > .ant-menu .ant-menu-item-selected {
  color: #4e1566; }

.logo-text.white {
  color: #fff; }

.logo-wrap {
  height: 64px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -26px;
  display: flex; }
  .logo-wrap.white .logo-text {
    color: #ffffff; }

.bg-shape {
  z-index: -1;
  opacity: .6;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
  max-width: 100%;
  display: block; }

.bg-shape._1 {
  width: 80vw;
  margin-top: -20vh;
  margin-left: -10vw; }

.bg-shape._2 {
  margin-top: -10vw;
  margin-left: -10vw;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto; }

.bg-shape._3 {
  width: 80vw;
  margin-bottom: -5vw;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%; }

.card {
  background-color: #fff;
  border-radius: 24px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 60px 30px 20px;
  font-size: 18px;
  display: flex;
  position: relative;
  box-shadow: 2px 3px 7px rgba(113, 55, 138, 0.2);
  margin-top: 15px; }
  .card.image {
    padding: 30px 30px 30px;
    margin-top: 0px; }

.card.with-img {
  max-width: 780px;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding: 0;
  font-size: 16px; }

.button {
  display: inline-block;
  background-color: #4e1566;
  border-radius: 100px;
  margin-top: 10px;
  padding: 10px 30px;
  font-size: 16px;
  color: #ffffff;
  font-family: Manrope, sans-serif;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.button:hover {
  opacity: 0.8;
  color: #ffffff; }

.ant-modal .ant-modal-title {
  color: #4e1566; }

:where(.css-dev-only-do-not-override-nllxry).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-nllxry).ant-menu-light > .ant-menu .ant-menu-item-selected {
  background-color: #e6e3e9; }

:where(.css-dev-only-do-not-override-nllxry).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-nllxry).ant-menu-light > .ant-menu .ant-menu-item-selected {
  color: #4e1566; }

.ant-menu-item.ant-menu-item-active {
  background-color: #e6e3e9; }
  .ant-menu-item.ant-menu-item-active:focus, .ant-menu-item.ant-menu-item-active:target {
    background-color: #4e1566 !important;
    color: #ffffff !important; }

.btn-link-secondary {
  border: 2px solid #4e1566;
  color: #4e1566;
  font-weight: 600;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  border-radius: 30px;
  display: inline-block;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 30px;
  padding-right: 30px;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  .btn-link-secondary:hover {
    color: #4e1566;
    background-color: rgba(113, 55, 138, 0.1); }

.custom-text-link {
  color: #1677ff !important;
  font-weight: 700;
  text-decoration: none !important; }
  .custom-text-link:hover {
    opacity: 0.7; }

.text-link {
  font-weight: 700; }
