@import "../../styles/elements";

.dashboard {
    color: green;
}

.dashboard-content-wrapper {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 600px;
    min-height: 920px;

    .graves-statistic-wrapper {
        margin-top: 30px;
    }

    h1, h2 {
        text-align: center;
    }

    .statistic-list-item {
        margin-bottom: 15px;
    }
}

.dashboard-page {
}

.statistic-modal {
    .ant-modal-content {
        // background-color: #f3f3f3;
    }

    .ant-modal-header {
        background-color: transparent;
    }
}

.statistic-modal-wrap {
    .info-card-wrapper {
        background-color: #f3f3f3;
        padding-top: 30px;
        padding-bottom: 30px;
        border-radius: 12px;
        .ant-card {
            max-width: 380px;
            margin: 0 auto;
        }
    }

    .graves-list-wrapper {
        margin-top: 60px;
        margin-bottom: 60px;

        h3 {
            text-align: center;
        }
    }

    .modal-footer {
        max-width: 400px;
        margin: 0px auto 0px auto;

        button {
            width: 100%;
        }
        .secondary-button {
            margin-top: 10px;
        }
    }

    .graves-listing-block {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .ant-empty {
            margin: 0 auto;
            background-color: white;
            padding: 15px;
            border-radius: 12px;
        }
    }

    .single-grave-item {
        border: 1px solid #e1e1e1;
        background-color: white;
        max-width: 220px;
        width: 100%;
        border-radius: 12px;
        cursor: pointer;
        margin-bottom: 15px;
        margin-left: 10px;
        margin-right: 10px;
        @include box-shadow(0 0 5px 0px rgba(0,0,0,0.1));
        @include transition(all 0.3s ease-in-out);

        &:hover {
            transform: scale(1.05);
        }

        .title {
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            padding: 5px;
            padding-left: 10px;
            padding-right: 10px;
        }

        .image-content {
            text-align: center;
            width: 100%;
            height: 200px;
            background-size: cover;
            background-position: center;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }

    }
}

.statistic-card {
    cursor: pointer;
    @include transition(all 0.3s ease-in-out);
    &:hover {
        transform: scale(1.05);
    }

    .right-statistic-col {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            margin-top: 5px;
            margin-bottom: 0px;
            font-weight: 500;
        }
    }

    .spacer {
        margin-top: 7px;
        display: block;
    }

    .statistic-title {
        font-weight: 500;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .statistic-number {
        font-weight: 500;
        font-size: 26px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 575px) {
    .dashboard-content-wrapper {
        background-size: 100%;
        min-height: 1030px;
    }
}
