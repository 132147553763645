@import "../../styles/elements";

.top-about-us-block {
    width: 100%;
    min-height: 100vh;
    padding-top: 163px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: $theme-violet;
    text-align: center;
    margin-bottom: 120px;

    .block-inner {
        background-color: $color-white;
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;
        padding: 30px;
        @include box-shadow(0 2px 5px rgba(113, 55, 138, .2));
        position: relative;
        overflow: hidden;
        padding-top: 50px;
    }

    .content-wrapper {
        padding-left: 260px;
        padding-right: 260px;
    }


    .contant-wrapper {
        max-width: 800px;
        margin: 0 auto;
        background-color: white;
        padding: 15px;
        border-radius: 50px;
    }

    h1 {
        margin-bottom: 70px;
    }

    h1, h2, h3, h4, h5, p {
        color: $theme-violet;
        font-family: Manrope, sans-serif;
        text-shadow: 0px 0px 4px #ffffff;
    }

    p {
        font-size: 18px;
    }

    .left-container {
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .right-container {
        text-align: center;
    }

    .about-logo {
        max-width: 700px;
        margin-top: -30px;
    }

    .flowers-left {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        max-width: 450px;
        width: 100%;
    }

    .flowers-right {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        max-width: 450px;
        width: 100%;
    }

    .parallax-roundel-wrap {
      z-index: 10;
      width: 60px;
      height: 60px;
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      border: 1px solid #fff;
      border-radius: 100px;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
      margin-right: -119px;
      padding: 20px;
      display: flex;
      position: absolute;
      top: 0%;
      bottom: auto;
      left: auto;
      right: 0%;
      box-shadow: 0 2px 5px rgba(113, 55, 138, .2);
    }

    .qr-code-lottie {
        width: 100px;
        height: 100px
    }

    @media only screen and (max-width: 991px) {

        padding-top: 150px;
        height: auto;

        .parallax-roundel-wrap {
            position: relative;
            margin-right: 0px;
            margin-top: 0px;
        }

        .flowers-left {
            max-width: 200px;
        }

        .content-wrapper {
            padding-left: 60px;
            padding-right: 60px;
        }

        .flowers-right {
            max-width: 200px;
        }
    }

    @media only screen and (max-width: 480px) {
        padding-top: 120px;

        .content-wrapper {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}
