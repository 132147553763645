@import "../../styles/elements";

.bg-absolute {
    position: absolute;
}

.login-wrap {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $body-bg-color;
}
.login-info {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login-info__text {
    z-index: 1;
    max-width: 544px;
    padding: 40px 32px 40px;
    text-align: center;

    .image-virtual-grave {
        max-width: 400px;
        width: 100%;
    }
    .title {
        margin-top: 16px;
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: 600;
        color: $color-white;
    }
    .description {
        font-size: 14px;
        line-height: 1.71;
        color: $color-white;
        margin-top: 0px;
    }
}
.main-logo {
    display: block;
    max-width: 240px;
    width: 100%;
    margin: 7px auto;
}

.logo-title {
    margin-top: 0px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
}
.login-form-box {
    flex-grow: 1;
    flex-shrink: 0;
    margin-top: -16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 24px 40px 34px;
    z-index: 2;
    background-color: $color-white;
    .form {
        width: 100%;
        max-width: 368px;
        margin: 0 auto;
    }
    .form-title {
        font-family: $primary-font-family;
        font-style: normal;
        font-weight: bold;
        font-size: $font-size-title;
        line-height: 36px;
        display: flex;
        align-items: center;
    }
    .ant-form-item-label label {
        font-size: 16px;
        line-height: 1.5;
    }
}

.login-wrap {
    background: $color-white !important;

    .login-info-inner {
        .lotus-img {
            width: 80px;
        }
        .logo-text {
            font-size: 24px;
        }
        .logo-wrap {
            margin-bottom: 15px;
            cursor: pointer;
        }
    }
}

@media only screen and (min-width: 768px) {
    .login-wrap {
        flex-direction: row;
        height: 100vh;
    }
    .login-form-box {
        .form {
            margin-top: 17.5vh;
        }
        .form-title {
            font-size: $font-size-title;
            margin-bottom: 36px;
            text-align: left;
        }
    }
    .login-info {
        max-width: 55vw;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        overflow: hidden;
    }
    .login-info__text {
        position: absolute;
        .main-logo {
            // max-width: 120px;
        }
        .title {
            margin-top: 24px;
            margin-bottom: 16px;
            font-size: 24px;
            line-height: 1.42;
        }
        .description {
            font-size: 16px;
            line-height: 1.5;
        }
    }
}

@media only screen and (max-width: 768px) {
    #root {
        overflow: hidden;
    }

    .login-info__text {
        width: 100%;
    }

    .login-info-inner {
        padding-left: 15px;
        padding-right: 15px;
    }

    picture {
        width: 100%;
    }
}
