@import "../../styles/elements";

.future-block {
    h1, h2, h3, h4, h5, p {
        color: $theme-violet;
        font-family: $primary-font-family;
    }

    p {
      font-size: 18px;
    }

    .general-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .parallax-roundel-wrap {
      z-index: 10;
      width: 100px;
      height: 100px;
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      border: 1px solid #fff;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      margin-right: -119px;
      padding: 20px;
      display: flex;
      position: absolute;
      top: 0%;
      bottom: auto;
      left: auto;
      right: 0%;
      box-shadow: 0 2px 5px rgba(113, 55, 138, .2);
    }

    .parallax-roundel-wrap.future {
      width: 200px;
      height: 200px;
      margin-top: -56px;
      margin-right: -30px;
      padding: 10px;
      top: 0%;
      bottom: auto;
      left: auto;
      right: 0%;
    }

    .copy-side {
      margin: 50px 20px 50px 50px;
    }

    .img-side {
        text-align: center;
    }

    .side-img {
        max-width: 400px;
        width: 100%;
    }
    .right-container {
        overflow: hidden;
    }

    .button {
        margin-top: 10px;
    }

    @media only screen and (max-width: 767px) {
        .parallax-roundel-wrap.future {
          margin-top: 280px;
          width: 150px;
          height: 150px;
        }
    }

    @media only screen and (max-width: 520px) {
        .parallax-roundel-wrap.future {
          margin-top: 350px;
        }
    }

    @media only screen and (max-width: 436px) {
        .parallax-roundel-wrap.future {
          bottom: 0;
          margin-top: auto;
          margin-bottom: 150px;
        }
    }
}
