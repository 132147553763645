@import "../../styles/elements";

.contact-us-block {
    width: 100%;
    padding-top: 163px;
    padding-bottom: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: $theme-violet;

    .block-inner {
        background-color: $color-white;
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;
        padding: 30px;
        @include box-shadow(0 2px 5px rgba(113, 55, 138, .2));
        position: relative;
        overflow: hidden;
        padding-top: 50px;
    }

    .flowers-left {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        max-width: 450px;
        width: 100%;
    }

    .flowers-right {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        max-width: 450px;
        width: 100%;
    }

    h1 {
        text-align: center;
        font-weight: 600;
    }

    p, h3 {
        text-align: center;
    }

    .contact-details-wrapper {
        margin-top: 50px;
    }

    h1, h2, h3, h4, h5, p {
        color: $theme-violet;
        font-family: $primary-font-family;
    }

    .divider {
        border-bottom: 1px solid $theme-violet;
        max-width: 250px;
        margin: 20px auto;
    }

    p {
        font-size: 18px;
    }

    a {
        color: $theme-violet;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }

    .ant-collapse {
        margin-top: 30px;
        background-color: $theme-violet;
        max-width: 970px;
    }

    .ant-collapse-header {
        color: $color-white !important;
        font-size: 20px;
    }

    @media only screen and (max-width: 991px) {

        .flowers-left {
            max-width: 200px;
        }

        .flowers-right {
            max-width: 200px;
        }

    }
}
